body {
  margin: 0;
  padding: 0;
  overflow: none;
  font-family: 'Bevan', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: #E2EDEE;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: grid;
  place-items: center;
  height: 100vh;
}

.titleWrapper{
  margin: 0 0 2em;
}

.finished-board {
  background-image: url(../public/img/art-finished.jpg);
}

.titleWrapper h1 {
  font-size: 2.5em;
  line-height: 0.2;
  font-weight: 300;
}

.titleWrapper hr {
  border: 0.1em solid #BCCFD9;
  background-color: #BCCFD9;
  box-shadow: 0 -0.2em #E5F4F2;
  border-radius: 100vh;
}

.x{
  cursor: pointer;
  font-size: 2em;
  line-height: 0;
}

.x:hover {
  cursor: pointer;
}

.board {
  position: absolute;
  padding: 0;
  margin: 0;
}

.boardWrapper {
  position: relative;
  display: grid;
  place-items: start start;
  background: url(../public/img/puzzle-bg.jpg);
  background-size: cover;
  margin: 0;
}

.tile {
  position: absolute;
  font-size: 20px;
  list-style: none;
  background-color: #BCCFD9;
  display: grid;
  place-items: center;
  border-radius: 12%;
  box-sizing: border-box;
  border: #BCCFD9 solid 2px;
}

button {
  font-size: 1em;
  font-weight: 200;
  line-height: 2;
  padding: 0.2em 1.2em;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 600;
  border-radius: 100vh;
  cursor: pointer;
}

.full-screen-overlay {
  display: grid;
  place-items: center;
  position: fixed;
  top:0;
  left:0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.6);
}

.start-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em 2.5em 1.8em 2.5em;
  border-radius: 0.625em;
  background-color: white;
}

.full-screen-overlay p {
  font-size: 2rem;
  line-height: 0.2;
  font-weight: 300;
}

.restart {
  position: relative;
  background-color: rgba(0,0,0,0);
  width: fit-content;
}

.win-wrapper {
  width: 100%;
  margin: 1.5em 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.win-text {
  font-size: clamp(1.2rem, 3.5vw, 2rem);
  line-height: 0;
}